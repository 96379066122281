import React from 'react';

const EmployerJobsData = [
  {
    title: "Software Developer",
    description: "Seeking an experienced software developer proficient in modern programming languages. Responsible for building scalable applications.",
    total_candidates: 110,
    candidates_interviewed: 43,
    candidates_shortlisted: 11,
    candidates_hired: 2
  },
  {
    title: "Digital Marketing Specialist",
    description: "A creative role focusing on digital campaigns, SEO strategies, and enhancing online presence to boost company's brand.",
    total_candidates: 157,
    candidates_interviewed: 21,
    candidates_shortlisted: 19,
    candidates_hired: 0
  },
  {
    title: "UI/UX Designer",
    description: "Design intuitive user interfaces, enhancing user experience across web and mobile platforms. Portfolio showcasing previous work required.",
    total_candidates: 192,
    candidates_interviewed: 45,
    candidates_shortlisted: 13,
    candidates_hired: 0
  },
  {
    title: "Content Writer",
    description: "Craft engaging content for various platforms. Must have excellent writing skills and the ability to produce creative content under tight deadlines.",
    total_candidates: 51,
    candidates_interviewed: 23,
    candidates_shortlisted: 10,
    candidates_hired: 2
  },
  {
    title: "Product Manager",
    description: "Lead product development from conception to launch, working across teams to prioritize features and deliver on product vision.",
    total_candidates: 88,
    candidates_interviewed: 32,
    candidates_shortlisted: 14,
    candidates_hired: 0
  },
  {
    title: "Data Analyst",
    description: "Analyze data sets to improve business decisions. Requires strong analytical skills and proficiency in data analysis tools.",
    total_candidates: 119,
    candidates_interviewed: 26,
    candidates_shortlisted: 14,
    candidates_hired: 0
  },
  {
    title: "Graphic Designer",
    description: "Create visual concepts to communicate ideas. Must be proficient in Adobe Creative Suite and have a strong portfolio of creative work.",
    total_candidates: 110,
    candidates_interviewed: 43,
    candidates_shortlisted: 11,
    candidates_hired: 2
  },
  {
    title: "SEO Specialist",
    description: "Improve website rankings on major search engines. Must have in-depth knowledge of SEO strategies and analytics tools.",
    total_candidates: 157,
    candidates_interviewed: 21,
    candidates_shortlisted: 19,
    candidates_hired: 0
  },
  {
    title: "Cybersecurity Analyst",
    description: "Protect company's information systems from cyber threats. Requires knowledge in security protocols and breach detection methods.",
    total_candidates: 192,
    candidates_interviewed: 45,
    candidates_shortlisted: 13,
    candidates_hired: 0
  },
  {
    title: "Social Media Manager",
    description: "Manage and grow online community through social media platforms. Requires experience in social media strategy and content creation.",
    total_candidates: 51,
    candidates_interviewed: 23,
    candidates_shortlisted: 10,
    candidates_hired: 2
  },
  {
    title: "Cloud Solutions Architect",
    description: "Design and deploy scalable, secure cloud infrastructure. Requires expertise in cloud services and architecture.",
    total_candidates: 88,
    candidates_interviewed: 32,
    candidates_shortlisted: 14,
    candidates_hired: 0
  }
  // You can continue to add more job entries as needed...
];

export default EmployerJobsData;
