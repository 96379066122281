import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ label, options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className="relative inline-block">
      <button onClick={() => setIsOpen(!isOpen)} className="px-2 py-1 rounded-md border drop-shadow-sm flex items-center space-x-2 bg-white border border-gray-300 hover:bg-teal-700 hover:text-white">
        {/* Display the label */}
        <span>{label}</span>
        {/* Optional: Include an icon here to indicate it's a dropdown */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 9.75l-7.5 7.5-7.5-7.5" />
        </svg>
      </button>
      {isOpen && (
        <ul className="absolute z-10 mt-1 w-48 bg-white border ">
          {options.map((option, index) => (
            <li key={index} className="px-2 py-2 hover:bg-background-gray cursor-pointer" onClick={() => {
              onSelect(option);
              setIsOpen(false); // Optionally close the dropdown on selection
            }}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
