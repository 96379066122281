import React from 'react';

function TopCandidates() {
    const candidates = [
        { name: "Jane Doe", position: "Software Engineer", date: "2023-03-15", duration: "45 mins", score: 97 },
        { name: "John Smith", position: "Data Analyst", date: "2023-03-16", duration: "30 mins", score: 94 },
        { name: "Alice Johnson", position: "Product Manager", date: "2023-03-17", duration: "60 mins", score: 93 },
        { name: "Bob Brown", position: "UX Designer", date: "2023-03-18", duration: "50 mins", score: 90 },
        { name: "Carol Danvers", position: "DevOps Engineer", date: "2023-03-19", duration: "40 mins", score: 86 }
    ];

    const getScoreColor = (score) => {
      const colors = [
        "#dc2626", // 0-9% red
        "#f87171", // 10-19% light red
        "#fb923c", // 20-29%
        "#fbbf24", // 30-39%
        "#facc15", // 40-49%
        "#a3e635", // 50-59%
        "#4ade80", // 60-69%
        "#34d399", // 70-79%
        "#2dd4bf", // 80-89%
        "#0d9488"  // 90-100%
      ];
      const index = Math.min(Math.max(Math.floor(score / 10), 0), 9);
      return colors[index];
    };




    return (
        <div>
            <div className="flex flex-row">
                <span className="sm-title mr-4">Top Candidates</span>
                <button className="rounded-md border-2 border-teal-700 hover:bg-teal-700 hover:text-white px-4 py-1 subheader transition-colors duration-150 ease-in-out">
                    View All
                </button>
            </div>

            <div className="overflow-x-auto mt-4 shadow rounded-lg">
                <table className="min-w-full border-collapse">
                    <thead className="bg-white">
                        <tr>
                            <th className="px-5 py-3 border-b subheader">
                                Name
                            </th>
                            <th className="px-5 py-3 border-b subheader">
                                Position
                            </th>
                            <th className="px-5 py-3 border-b subheader ">


                            <div className="flex flex-row">
                            <div>
                                Date
                                </div>
                                <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-2 mt-1 w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                                </div>
                                </div>
                            </th>



                              <th className="px-5 py-3 border-b subheader ">

                              <div className="flex flex-row">
                              <div>
                                  Score
                                  </div>
                                  <div>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-2 mt-1 w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                  </svg>
                                  </div>

                                  </div>
                              </th>



                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {candidates.map((candidate, index) => (
                            <tr key={index}>
                                <td className="px-5 py-2 border-b par">
                                    {candidate.name}
                                </td>
                                <td className="px-5 py-2 border-b par">
                                    {candidate.position}
                                </td>
                                <td className="px-5 py-2 border-b par">
                                    {candidate.date}
                                </td>
                                <td className="px-5 py-2 border-b par flex ">
                                    <div className="relative  w-10 h-10">
                                        <div className="absolute inset-0 flex items-center justify-center" style={{ fontSize: "0.75rem" }}>
                                            {candidate.score}
                                        </div>
                                        <svg viewBox="0 0 36 36" className="w-full h-full">
                                              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#e5e7eb" strokeWidth="4" strokeDasharray="100, 100"></path>
                                              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke={getScoreColor(candidate.score)} strokeWidth="4" strokeDasharray={`${candidate.score}, 100`}></path>
                                          </svg>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TopCandidates;
