import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import SignInPage from './Pages/SignInPage';
import RegisterPage from './Pages/RegisterPage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import EmployerDashboard from './Pages/EmployerDashboardPage'; // Ensure this path is correct
import TestPage from './Pages/TestPage';
import EmployerCandidates from './Pages/EmployerCandidatesPage';



import EmployerJobs from './Pages/EmployerJobsPage';
import EmployerAddJob from './Pages/EmployerAddJobPage';
import EmployerTests from './Pages/EmployerTestsPage';
import EmployerReports from './Pages/EmployerReportsPage';
import EmployerSupport from './Pages/EmployerSupportPage';
import EmployerAccount from './Pages/EmployerAccountPage';
import IndividualTest from './Pages/IndividualTestPage';
import TestScore from './Pages/TestScorePage';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/employer-dashboard" element={<EmployerDashboard />} />
          <Route path="/test-page" element={<TestPage />} />
          <Route path="/employer-candidates" element={<EmployerCandidates />} />

      

          <Route path="/employer-jobs" element={<EmployerJobs />} />
            <Route path="/employer-add-job" element={<EmployerAddJob />} />
            <Route path="/employer-tests" element={<EmployerTests />} />
            <Route path="/employer-reports" element={<EmployerReports />} />
            <Route path="/employer-support" element={<EmployerSupport />} />
            <Route path="/employer-account" element={<EmployerAccount />} />
            <Route path="/test" element={<IndividualTest />} />
            <Route path="/test-score" element={<TestScore />} />
            <Route path="/test/:testId" element={<IndividualTest />} />

        // Define other routes here
      </Routes>
    </Router>
  );
}

export default App;
