import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/EmployerComponents/Sidebar';
import Searchbar from '../components/EmployerComponents/Searchbar';
import EmployerJobsData from '../Data/EmployerJobsData';
import EditJobPopup from '../components/EmployerComponents/EditJobPopup';
import Dropdown from '../components/SharedComponents/Dropdown';

const EmployerJobs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 9;
  const navigate = useNavigate();

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [currentJobToEdit, setCurrentJobToEdit] = useState(null);

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = EmployerJobsData.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = pageNumber => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(EmployerJobsData.length / jobsPerPage);

  return (
    <div className="flex min-h-screen bg-background-gray">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <Searchbar />





        <div className=" px-6">
          <div className="flex justify-between items-center mb-5 par  ">
            <div className="flex items-center space-x-4">
              <i className="fi fi-rr-filter text-xl"></i> {/* Filter Icon */}
              <div className="flex  space-x-2">
                {/* Date Dropdown */}
                <Dropdown className=""
                  label="Date"
                  options={["Most Recent", "Least Recent"]}
                  onSelect={(selectedOption) => console.log("Selected Date:", selectedOption)}
                />
                {/* Interviews Dropdown */}
                <Dropdown
                  label="Interviews"
                  options={["Most Scheduled", "Least Scheduled"]}
                  onSelect={(selectedOption) => console.log("Selected Interviews:", selectedOption)}
                />
                {/* Shortlisted Dropdown */}
                <Dropdown
                  label="Shortlisted"
                  options={["Most Shortlisted", "Least Shortlisted"]}
                  onSelect={(selectedOption) => console.log("Selected Shortlisted:", selectedOption)}
                />
                {/* Hired Dropdown */}
                <Dropdown
                  label="Hired"
                  options={["Most Hired", "Least Hired"]}
                  onSelect={(selectedOption) => console.log("Selected Hired:", selectedOption)}
                />
              </div>
            </div>
          </div>







          <div className="grid grid-cols-3 gap-4">
            {currentJobs.map((job, index) => (
              <div key={index} className="bg-white drop-shadow-md p-4 min-w-80 rounded-md">
                <div className="flex justify-between items-start ">
                  <h3 className="par font-bold text-new-font">{job.title}</h3>
                  <i class="fi fi-rr-pen-square cursor-pointer"></i> {/* Edit icon */}
                </div>
                <div className="mini">Date Posted: March 21, 2024 | Application End Date: July 30, 2024</div>
                <p className="subheader font-normal mt-4 mb-4 py-2 border-y border-gray-200">{job.description}</p>
                <div className="subheader font-normal mb-4">Test: <span className="bg-wise-yellow border border-gray-200 drop-shadow-sm ml-2 rounded-md py-1 px-2">Sample Test</span></div>
                <div className="grid grid-cols-4 drop-shadow-sm border-gray-200 border rounded-md">
                  {/* Candidates Column */}
                  <div className="bg-very-light-green p-2 rounded border-r border-gray-200">
                    <span className="mini">Candidates</span>
                    <div className="flex justify-between items-center mt-1 ">
                      <i className="fi fi-rr-users-alt sm-title"></i>
                      <span className="ml-2 par font-bold">{job.total_candidates}</span>
                    </div>
                  </div>
                  {/* Interviews Column */}
                  <div className="bg-very-light-purple p-2 rounded border-r border-gray-200 ">
                    <span className="mini">Interviews</span>
                    <div className="flex justify-between items-center mt-1 ">
                      <i className="fi fi-rr-meeting sm-title"></i>
                      <span className="ml-2 par font-bold">{job.candidates_interviewed}</span>
                    </div>
                  </div>
                  {/* Shortlisted Column */}
                  <div className="bg-very-light-teal p-2 rounded border-r border-gray-200">
                    <span className="mini">Shortlisted</span>
                    <div className="flex justify-between items-center w-full mt-1 ">
                      <i className="fi fi-rr-circle-star sm-title"></i>
                      <span className="ml-2 par font-bold">{job.candidates_shortlisted}</span>
                    </div>
                  </div>
                  {/* Hired Column */}
                  <div className="bg-soft-pink p-2 ">
                    <span className="mini">Hired</span>
                    <div className="flex justify-between items-center mt-1">
                      <i className="fi fi-rr-handshake sm-title"></i>
                      <span className="ml-2 par font-bold">{job.candidates_hired}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
              <button key={number} onClick={() => paginate(number)} className={`px-4 py-2 m-1 border rounded ${currentPage === number ? 'bg-teal-700 text-white' : 'bg-white'}`}>
                {number}
              </button>
            ))}
          </div>
        </div>

        {isEditPopupOpen && <EditJobPopup job={currentJobToEdit} onClose={() => setIsEditPopupOpen(false)} />}
      </div>
    </div>
  );
};

export default EmployerJobs;
