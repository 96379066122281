import React from 'react';
import { useNavigate } from 'react-router-dom';

function ForgotPasswordPage() {
  let navigate = useNavigate();

  return (
    <div className="flex min-h-screen">
      {/* Left Side: Forgot Password Form */}
      <div className="bg-background-gray flex flex-1 flex-col justify-center items-center px-4">
        <button onClick={() => navigate('/')} className="mb-4 w-full">
          <h1 className="big-title text-center mb-10">
            <span className="text-teal-500">Evalu</span><span className="text-teal-800">Hire</span>
          </h1>
        </button>
        <div className="w-full max-w-xs">
          <h2 className="md-title text-left">Forgot Password?</h2>
          <p className="par mb-8 text-left">No worries, we'll send you password reset instructions.</p>
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="email" placeholder="Email" />
          <button className="border-white mb-4 bg-teal-800 hover:bg-white hover:border hover:border-teal-800 text-white hover:text-teal-900 font-bold py-2 px-4 rounded w-full mb-4" onClick={() => console.log('Reset Password')}>Reset Password</button>
          <p className="par">Create a new account? <a className="text-blue-600 hover:text-blue-800" href="#" onClick={(e) => {e.preventDefault(); navigate('/register');}}>Register</a>.</p>
        </div>
      </div>

      {/* Right Side: Image Background */}
      <div className="flex-1" style={{ backgroundImage: "url('https://i.ibb.co/TKWsD46/image-8.png')", backgroundSize: 'cover', backgroundPosition: 'center' }}>
        {/* No content here, just background image */}
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
