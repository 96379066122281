import React from 'react';
import { useNavigate } from 'react-router-dom';

function TestPage() {
  return(

<div>

    <div className="grid grid-cols-3 bg-background-gray pb-20">

    <div className="subheader pl-5 pt-5"> Our Advantage </div>

    <div className="big-title col-start-1 col-span-1 pl-5 mb-16 ">

    EvaluHire is a leading Software company helping small businesses hire better!


    </div>

    <div className="md-title col-start-1 col-span-1 pl-5 ">

    Hire Well & Grow Well


    </div>

    <div className="par col-start-1 col-span-1 pl-5 ">

    Leverage EvaluHire's direct connection to the Federal Reserve. Pull and push money to any bank account and create flexible FDIC insured accounts.

    </div>

    <div className="mini mt-5 col-start-1 col-span-1 pl-5 ">

    Terms & Conditions May Apply

    </div>





    </div>

<div className=" p-5 mt-10 md-title"> Main Colors </div>

<div className=" pl-5 flex flex-row">
<div className="bg-very-dark-green w-40 h-40"> </div>
<div className="bg-dark-green w-40 h-40"> </div>
<div className="bg-medium-green w-40 h-40"> </div>
<div className="bg-light-green w-40 h-40"> </div>
<div className="bg-very-light-green w-40 h-40"> </div>
</div>


<div className=" p-5 mt-10 md-title"> UI Colors </div>

<div className=" pl-5 flex flex-row">
<div className="bg-ui-green w-40 h-40"> </div>
<div className="bg-ui-pink w-40 h-40"> </div>
<div className="bg-ui-blue w-40 h-40"> </div>
<div className="bg-ui-purple w-40 h-40"> </div>
<div className="bg-ui-yellow w-40 h-40"> </div>
</div>





</div>






  );
}

export default TestPage;
