import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/EmployerComponents/Sidebar';
import Searchbar from '../components/EmployerComponents/Searchbar';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const EmployerAddJob = () => {
  let navigate = useNavigate();
  const [jobType, setJobType] = useState('');
  const [salaryType, setSalaryType] = useState('');

  const [jobPostDate, setJobPostDate] = useState(null);
  const [applicationEndDate, setApplicationEndDate] = useState(null);

  // Make sure to prevent the default form submit behavior to avoid page reloads
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you'll handle your form submission to the backend
    console.log("Form submitted with job type:", jobType, "and salary type:", salaryType);
    // Optionally navigate to another page on successful form submission
    // navigate('/some-success-page');
  };

  return (
    <div className="flex min-h-screen bg-background-gray">
      <Sidebar navigateTo={(path) => () => navigate(path)} />
      <div className="flex-1 ">
        <Searchbar />
        <div className="mb-6 p-5">
          <h1 className="md-title mb-4">Add Job</h1>
          <form onSubmit={handleSubmit} className="space-y-4">


          <div>
            <label className="block par">Job Title</label>
            <input type="text" className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm" placeholder="" />
          </div>

          <div>
            <label className="block par">Job Description</label>
            <textarea className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" rows="4" placeholder=""></textarea>
          </div>

          <div>
            <label className="block par">Job Requirements</label>
            <textarea className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" rows="4" placeholder=""></textarea>
          </div>

          <div>
            <label className="block par">Select Job Test</label>
            <select className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm" value={jobType} onChange={(e) => setJobType(e.target.value)}>
              <option value="Test Option 1">Test Option 1 </option>
              <option value="Test Option 2">Test Option 2</option>
              <option value="Test Option 3">Test Option 3</option>
            </select>
          </div>

<div className="flex space-x-4">
          <div className="">
       <label htmlFor="jobPostDate" className="block par ">Job Post Date</label>
       <DatePicker
         id="jobPostDate"
         selected={jobPostDate}
         onChange={date => setJobPostDate(date)}
         className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
       />
     </div>
     <div className="">
       <label htmlFor="applicationEndDate" className="block par">Application End Date</label>
       <DatePicker
         id="applicationEndDate"
         selected={applicationEndDate}
         onChange={date => setApplicationEndDate(date)}
         className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
       />
     </div>
     </div>



          <div>
            <label className="block par">Job Type</label>
            <select className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm" value={jobType} onChange={(e) => setJobType(e.target.value)}>
              <option value="">Select Job Type</option>
              <option value="In-Location">In-Location</option>
              <option value="Remote">Remote</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>

          {(jobType === 'In-Location' || jobType === 'Hybrid') && (
            <div>
              <label className="block par">Location Address</label>
              <input type="text" className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm" placeholder="" />
            </div>
          )}

          <div>
            <label className="block par">Salary Type</label>
            <select className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm" value={salaryType} onChange={(e) => setSalaryType(e.target.value)}>
              <option value="">Select Salary Type</option>
              <option value="Range">Range</option>
              <option value="Flat">Flat</option>
              <option value="Hide">Hide</option>
            </select>
          </div>

          {salaryType === 'Range' && (
            <div className="grid grid-cols-2 gap-4">
              <input type="text" className="form-input mt-1 block w-full p-2" placeholder="Low Range" />
              <input type="text" className="form-input mt-1 block w-full p-2" placeholder="High Range" />
            </div>
          )}

          {salaryType === 'Flat' && (
            <input type="text" className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm" placeholder="Flat Salary" />
          )}




            <button type="submit" className="bg-white border border-teal-700 hover:bg-teal-700 hover:text-white px-4 py-2 rounded-md subheader transition duration-150 ease-in-out">
              Publish
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployerAddJob;
