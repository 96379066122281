import React from 'react';

function Summary({ stats }) {
  const statItems = [
    { label: 'Jobs', value: stats.jobs, bgColor: 'bg-white', borderColor: 'border-teal-400' },
    { label: 'Tests', value: stats.tests, bgColor: 'bg-white', borderColor: 'border-pink-400' },
    { label: 'Candidates', value: stats.candidates, bgColor: 'bg-white', borderColor: 'border-yellow-400' },
    { label: 'Shortlisted', value: stats.shortlisted, bgColor: 'bg-white', borderColor: 'border-green-400' },
    { label: 'Hired', value: stats.hired, bgColor: 'bg-white', borderColor: 'border-purple-400' },
    { label: 'Tickets', value: stats.Tickets, bgColor: 'bg-white', borderColor: 'border-blue-400' },
  ];

  return (
    <div className="flex justify-between ">
      {statItems.map((item, index) => (
        <div key={index} className={`flex-1 ${item.bgColor} border-l-4 ${item.borderColor} text-black p-4 mr-4 last:mr-0 rounded shadow`}>
          <p className="subheader mb-2">{item.label}</p>
          <p className="md-title">{item.value}</p>
        </div>
      ))}
    </div>
  );
}

export default Summary;
