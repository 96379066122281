import React from 'react';


const IndividualTestData = {
  id: 1,
  title: 'Front-End Developer Test',
  questions: [
    {
      id: 101,
      text: 'What does HTML stand for?',
      answers: [
        { id: 1001, text: 'Hyper Text Markup Language', isCorrect: true },
        { id: 1002, text: 'Hot Mail', isCorrect: false },
        { id: 1003, text: 'How to Make Lasagna', isCorrect: false },
      ],
    },
    {
      id: 102,
      text: 'Which language runs in a web browser?',
      answers: [
        { id: 1004, text: 'Java', isCorrect: false },
        { id: 1005, text: 'C', isCorrect: false },
        { id: 1006, text: 'Python', isCorrect: false },
        { id: 1007, text: 'JavaScript', isCorrect: true },
      ],
    },

    {
  id: 103,
  text: 'What is the purpose of CSS?',
  answers: [
    { id: 1008, text: 'To structure web pages', isCorrect: false },
    { id: 1009, text: 'To style web pages', isCorrect: true },
    { id: 1010, text: 'To add interactivity to web pages', isCorrect: false },
    { id: 1011, text: 'To store data in web pages', isCorrect: false },
  ],
},
{
  id: 104,
  text: 'Which HTML tag is used to define an internal style sheet?',
  answers: [
    { id: 1012, text: '<style>', isCorrect: true },
    { id: 1013, text: '<script>', isCorrect: false },
    { id: 1014, text: '<css>', isCorrect: false },
    { id: 1015, text: '<link>', isCorrect: false },
  ],
},
{
  id: 105,
  text: 'Which property is used to change the font of an element?',
  answers: [
    { id: 1016, text: 'font-style', isCorrect: false },
    { id: 1017, text: 'font-family', isCorrect: true },
    { id: 1018, text: 'text-style', isCorrect: false },
    { id: 1019, text: 'font-weight', isCorrect: false },
  ],
},
{
  id: 106,
  text: 'How do you create a function in JavaScript?',
  answers: [
    { id: 1020, text: 'function:myFunction()', isCorrect: false },
    { id: 1021, text: 'function myFunction()', isCorrect: true },
    { id: 1022, text: 'function = myFunction()', isCorrect: false },
    { id: 1023, text: 'create myFunction()', isCorrect: false },
  ],
},
{
  id: 107,
  text: 'How do you call a function named "myFunction"?',
  answers: [
    { id: 1024, text: 'call function myFunction()', isCorrect: false },
    { id: 1025, text: 'call myFunction()', isCorrect: false },
    { id: 1026, text: 'myFunction()', isCorrect: true },
    { id: 1027, text: 'execute myFunction()', isCorrect: false },
  ],
},
{
  id: 108,
  text: 'How to write an IF statement in JavaScript?',
  answers: [
    { id: 1028, text: 'if i = 5 then', isCorrect: false },
    { id: 1029, text: 'if (i == 5)', isCorrect: true },
    { id: 1030, text: 'if i == 5 then', isCorrect: false },
    { id: 1031, text: 'if i = 5', isCorrect: false },
  ],
},
{
  id: 109,
  text: 'How does a FOR loop start?',
  answers: [
    { id: 1032, text: 'for (i <= 5; i++)', isCorrect: false },
    { id: 1033, text: 'for i = 1 to 5', isCorrect: false },
    { id: 1034, text: 'for (i = 0; i <= 5; i++)', isCorrect: true },
    { id: 1035, text: 'for (i = 0; i <= 5)', isCorrect: false },
  ],
},
{
  id: 110,
  text: 'How can you add a comment in a JavaScript?',
  answers: [
    { id: 1036, text: '<!--This is a comment-->', isCorrect: false },
    { id: 1037, text: "'This is a comment", isCorrect: false },
    { id: 1038, text: '//This is a comment', isCorrect: true },
    { id: 1039, text: '/*This is a comment*/', isCorrect: false },
  ],
}
    // Add more questions as needed
  ],
};


export default IndividualTestData;
