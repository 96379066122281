import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/EmployerComponents/Sidebar';
import Searchbar from '../components/EmployerComponents/Searchbar';
import IndividualTestData from '../Data/IndividualTestData';

const IndividualTest = () => {
  const navigate = useNavigate();
  const questionsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [test, setTest] = useState(IndividualTestData);
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const navigateTo = (path) => () => navigate(path);

  const handleAnswerChange = (questionId, answerId) => {
    setSelectedAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answerId,
    }));
  };

  const calculateScore = () => {
    const score = test.questions.reduce((acc, question) => {
      const correctAnswer = question.answers.find(ans => ans.isCorrect).id;
      if (selectedAnswers[question.id] === correctAnswer) {
        return acc + 1;
      }
      return acc;
    }, 0);

    navigate('/test-score', { state: { score, total: test.questions.length } });
  };

  // Calculate the current questions to display
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = test.questions.slice(indexOfFirstQuestion, indexOfLastQuestion);

  return (
    <div className="flex bg-background-gray min-h-screen">
      <Sidebar navigateTo={navigateTo} />
      <div className="flex-1">
        <Searchbar />
        <div className="p-4 pl-8">
          <div className="mx-auto mt-5">
            <h1 className="text-xl font-semibold mb-10">{test.title}</h1>
            {currentQuestions.map((question, questionIndex) => (
              <div key={question.id} className="mb-6">
                <h2 className="font-semibold mb-2">
                  Q{questionIndex + 1 + ((currentPage - 1) * questionsPerPage)}: {question.text}
                </h2>
                <div className="ml-4">
                  {question.answers.map((answer) => (
                    <div key={answer.id} className="flex items-center mb-2">
                      <input
                        type="radio"
                        id={`answer-${answer.id}`}
                        name={`question-${question.id}`}
                        className="mr-2"
                        onChange={() => handleAnswerChange(question.id, answer.id)}
                        checked={selectedAnswers[question.id] === answer.id}
                      />
                      <label htmlFor={`answer-${answer.id}`} className="cursor-pointer">{answer.text}</label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="flex justify-between mt-8">
              <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
                className="bg-teal-700 text-white px-4 py-2 rounded-md hover:bg-teal-600 disabled:bg-gray-400"
              >
                Previous
              </button>
              {currentPage < Math.ceil(test.questions.length / questionsPerPage) ? (
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  className="bg-teal-700 text-white px-4 py-2 rounded-md hover:bg-teal-600"
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={calculateScore}
                  className="bg-teal-700 text-white px-4 py-2 rounded-md hover:bg-teal-600"
                >
                  Finish Test
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualTest;
