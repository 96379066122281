import React from 'react';
import { useLocation } from 'react-router-dom';

const TestScore = () => {
  const location = useLocation();
  const { score, total } = location.state; // Receive total questions count

  return (
    <div className="p-4">
      <h1 className="text-xl font-semibold mb-4">Your Front-End Developer Test Score is:</h1>
      <p className="text-lg">{score} / {total}</p> {/* Display total questions dynamically */}
    </div>
  );
};

export default TestScore;
