import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const HiringSummaryChart = () => {
    const data = {
        labels: ['Total Candidates: 289', 'Interviewed: 46', 'Shortlisted: 16', 'Hired: 5'],
        datasets: [{
            data: [289, 46, 16, 5],
            backgroundColor: ['#f87171', '#facc15', '#c084fc', '#2dd4bf'],
            borderColor: ['#f87171', '#facc15', '#c084fc', '#2dd4bf'],
            borderWidth: 1,
            cutout: '60%',
        }],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start', // Left-align the labels
                labels: {
                    usePointStyle: true, // Use circles instead of rectangles
                    padding: 20, // Add some padding between lines
                    // Customize text to move color box to the next line
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets[0];
                        return chart.data.labels.map((label, index) => ({
                            text: label,
                            fillStyle: datasets.backgroundColor[index],
                            strokeStyle: datasets.borderColor ? datasets.borderColor[index] : datasets.backgroundColor[index],
                            lineWidth: datasets.borderWidth,
                            hidden: !chart.getDataVisibility(index),
                            index: index
                        }));
                    }
                }
            },
            datalabels: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div>
            <div className="sm-title">Candidate Stats</div>
            <div className="bg-white mt-4 rounded-lg shadow border p-4" style={{ width: '100%', height: '300px' }}>
                <Doughnut data={data} options={options} plugins={[ChartDataLabels]} />
            </div>
        </div>
    );
};

export default HiringSummaryChart;
