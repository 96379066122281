import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Assuming you have this CSS file; if not, you can remove this line
import App from './App'; // Ensure you have an App.js file or change this to your main component


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
