import React from 'react';
import { useNavigate } from 'react-router-dom';

function SignInPage() {
  let navigate = useNavigate();

  return (
    <div className="flex min-h-screen">
      {/* Left Side: Sign In Form */}
      <div className="bg-background-gray flex flex-1 flex-col justify-center items-center px-4">
        {/* EvaluHire Text centered at the top of the form */}
        <div className="w-full flex justify-center mb-10">
          <button onClick={() => navigate('/')} className="text-center">
            <h1 className="big-title">
              <span className="text-teal-500">Evalu</span><span className="text-teal-800">Hire</span>
            </h1>
          </button>
        </div>

        {/* Sign In Form */}
        <div className="w-full max-w-xs">
          <h2 className="md-title text-left">Sign In</h2>
          <p className="text-left mb-8">Get back to growing your team!</p>
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="email" placeholder="Email" />
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="password" placeholder="Password" />
          <a className="par text-left text-blue-600 hover:text-blue-800" href="#" onClick={(e) => {e.preventDefault(); navigate('/forgot-password');}}>Forgot password?</a>
          <button className=" mt-2 border-white bg-teal-800 hover:bg-white hover:border hover:border-teal-800 text-white hover:text-teal-900 font-bold py-2 px-4 rounded w-full mb-4" onClick={() => console.log('Sign In')}>Sign In</button>
          <p className="par text-left">Don't have an account? <a className="text-blue-600 hover:text-blue-800" href="#" onClick={(e) => {e.preventDefault(); navigate('/register');}}>Sign Up</a>.</p>
        </div>
      </div>

      {/* Right Side: Image Background */}
      <div className="flex-1" style={{ backgroundImage: "url('https://i.ibb.co/kG8DTDd/image-721.png')", backgroundSize: 'cover', backgroundPosition: 'center' }}>
        {/* No content here, just background image */}
      </div>
    </div>
  );
}

export default SignInPage;
