import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/EmployerComponents/Sidebar';
import Searchbar from '../components/EmployerComponents/Searchbar';
import Dropdown from '../components/SharedComponents/Dropdown';

// Placeholder data for tests
const testsData = [
  {
    title: "Marketing Strategy 101",
    description: "A fundamental test for understanding basic marketing strategies.",
  },
  {
    title: "Advanced JavaScript",
    description: "Test your knowledge on advanced concepts in JavaScript.",
  },
  {
    title: "Design Thinking Process",
    description: "Evaluate your understanding of the design thinking process.",
  },
];

const EmployerTests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const testsPerPage = 3;
  let navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  const indexOfLastTest = currentPage * testsPerPage;
  const indexOfFirstTest = indexOfLastTest - testsPerPage;
  const currentTests = testsData.slice(indexOfFirstTest, indexOfLastTest);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="flex min-h-screen bg-background-gray">
      <Sidebar navigateTo={navigateTo} />


      <div className="flex-1 flex flex-col">
        <Searchbar />
        <div className="p-4 bg-white border-b">
          <div className="flex space-x-2">
            <Dropdown label="Department" options={["Marketing", "Design", "Development", "Management", "Sales/Customer Support", "Account Management", "Other"]} onSelect={() => {}} />
            <Dropdown label="Difficulty" options={["Easy", "Medium", "Hard"]} onSelect={() => {}} />
            <Dropdown label="Type" options={["Skill-based", "Cognitive", "Personality"]} onSelect={() => {}} />
          </div>
        </div>

        <div className="p-8">
          {currentTests.map((test, index) => (
            <div key={index} className="bg-white shadow rounded-lg p-4 mb-4">
              <h3 className="font-bold">{test.title}</h3>
              <p>{test.description}</p>
              <button onClick={() => navigateTo(`/test/${index}`)} className="mt-2 px-4 py-2 bg-teal-700 text-white rounded hover:bg-teal-800">
                View Test
              </button>
            </div>
          ))}

          <div className="flex justify-center mt-8">
            {/* Pagination buttons */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerTests;
