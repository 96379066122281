import React from 'react';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  let navigate = useNavigate();

  return (
    <div className="bg-teal-950 flex flex-col justify-center items-center min-h-screen bg-blue-600 text-white">
      <h1 className="big-title text-teal-100 mb-5" > EvaluHire </h1>
      <h2 className="sm-title text-teal-100 mb-10">Hire Well Without Breaking The Bank!</h2>
      <div className="flex flex-col space-y-4">
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-64"
          onClick={() => navigate('/signin')}>
          Sign In
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-64"
          onClick={() => navigate('/register')}>
          Register
        </button>
      </div>
    </div>
  );
}

export default HomePage;
