import React, { useState } from 'react';

const InvitePopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        job: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div>
            <button
                className="bg-white border border-teal-700 hover:bg-teal-700 hover:text-white px-4 py-2 rounded-md subheader transition duration-150 ease-in-out"
                onClick={() => setIsOpen(true)}
            >
                Invite New
            </button>

            {isOpen && (
                <div className="fixed inset-0  overflow-y-auto h-full w-full" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded bg-white">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
</svg>

                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Invite New Candidate</h3>
                            <div className="mt-2 px-7 py-3">
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    className="input input-bordered p-3 input-primary w-full max-w-xs"
                                    onChange={handleInputChange}
                                />
                                <input
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    className="input input-bordered p-3 input-primary w-full max-w-xs my-4"
                                    onChange={handleInputChange}
                                />
                                <input
                                    name="phoneNumber"
                                    type="text"
                                    placeholder="Phone Number"
                                    className="input input-bordered p-3 input-primary w-full max-w-xs"
                                    onChange={handleInputChange}
                                />
                                <select
                                    name="job"
                                    className="select p-3 select-bordered w-full max-w-xs my-4"
                                    onChange={handleInputChange}
                                >
                                    <option disabled selected>Choose a job</option>
                                    <option>Job Placeholder 1</option>
                                    <option>Job Placeholder 2</option>
                                </select>
                            </div>
                            <div className="items-center p-3 px-4 py-3">
                                <button
                                    id="ok-btn"
                                    className="px-4 py-2 bg-teal-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-teal-400 focus:outline-none focus:ring-2 focus:ring-teal-300"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Send Invite
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InvitePopup;
