import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/EmployerComponents/Sidebar';
import Searchbar from '../components/EmployerComponents/Searchbar';
import Summary from '../components/EmployerComponents/Summary';
import TopCandidates from '../components/EmployerComponents/TopCandidates';
import RecentCandidates from '../components/EmployerComponents/RecentCandidates';
import HiringSummaryChart from '../components/EmployerComponents/HiringSummaryChart';
import OpenJobs from '../components/EmployerComponents/OpenJobs';

function EmployerDashboard() {
    let navigate = useNavigate();

    const stats = {
        jobs: 25,
        tests: 40,
        candidates: 120,
        shortlisted: 35,
        hired: 15,
        Tickets: 5,
    };

    const navigateTo = (path) => () => navigate(path);

    return (
        <div className="flex min-h-screen bg-background-gray">
            <Sidebar navigateTo={navigateTo} />
            <div className="flex-1 flex flex-col">

                    <Searchbar />

                <div className="flex-1 p-8 overflow-auto">
                    <Summary stats={stats} />

                    <div className="flex justify-between space-x-4 my-6">
                        <div className="w-3/4"> {/* This takes 2/3 of the space */}


                              <RecentCandidates />
                        </div>
                        <div className="w-1/4 flex flex-col items-stretch"> {/* This takes 1/3 of the space */}
                        <HiringSummaryChart />

                        </div>
                    </div>





                    <div className="flex justify-between space-x-4 my-6">
                    <div className=" w-2/3 ">
                    <TopCandidates />

                    </div>
                    <div className="w-1/3  flex   flex-col ">
                    <OpenJobs />

                    </div>
                    </div>







                    </div>

                </div>
            </div>

    );
}

export default EmployerDashboard;































//
