import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/EmployerComponents/Sidebar';
import Searchbar from '../components/EmployerComponents/Searchbar';


function EmployerReports() {

return (
  <div> <h1> Hello World </h1> </div>
);

}

export default EmployerReports;
