import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function Searchbar() {
    const navigate = useNavigate(); // Initialize useNavigate

    // Function to navigate to the employer-support page
    const navigateToSupport = () => navigate('/employer-support');

    return (
        <div className="px-6 py-6 bg-background-gray flex items-center">
            {/* Search Bar */}
            <div className="flex-grow relative">
                <input
                    type="text"
                    placeholder="Search Candidates, Jobs & Tests!"
                    className="w-full pl-10 pr-4 py-2 bg-white rounded-lg shadow-sm border border-gray-200 outline-none focus:ring-2 focus:ring-primary-button placeholder-gray-400 text-gray-700"
                    style={{ transition: 'all 0.3s' }}
                />
                <button
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-primary-button"
                    type="submit"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </button>
            </div>

            {/* Coins Icon */}
            <div onClick={navigateToSupport} className="ml-4 sm-title flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 hover:text-white hover:bg-teal-700 cursor-pointer">
                <i className="fi fi-rr-badge-dollar  hover:text-white pt-1  "></i>
            </div>

            {/* Notifications Icon */}
            <div onClick={navigateToSupport} className="ml-4 sm-title flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 hover:bg-teal-700 hover:text-white cursor-pointer">
                <i className="fi fi-rr-bell  pt-1 "></i>
            </div>

            {/* Profile Photo */}
            <div className="ml-4">
                <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLmYp4xkqBC0VAAeZjYUupndmEOfIHXQeFbKuEcNnMcw&s"
                    alt="Profile"
                    className="w-10 h-10 rounded-full"
                />
            </div>
        </div>
    );
}

export default Searchbar;
