import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to access the current path
  const navigateTo = (path) => () => navigate(path);
  const isActive = (path) => location.pathname === path; // Use location.pathname to get the current path

  // Apply text-white for active icons, and a different color (e.g., text-teal-700) for inactive icons
  const iconClass = (path) => `par font-bold mr-4 pt-1 ${isActive(path) ? 'text-white' : 'text-teal-700'}`;

  return (
    <div className="flex flex-col min-h-screen drop-shadow-md bg-white w-64 border-r border-gray-200" style={{ height: 'fit-content' }}>

      <div className="px-8 py-3 mb-10 flex flex-col items-center justify-center">
        <p className="big-title text-teal-500">Evalu<span className="text-teal-800">Hire</span></p>
      </div>

      <div className="flex flex-col flex-grow">
        <div className="flex flex-col">
          {/* Dashboard */}
          <button onClick={navigateTo('/employer-dashboard')} className={`flex items-center pl-7 py-4 px-3 subheader hover:border-l-teal-400 hover:border-l-4 ${isActive('/employer-dashboard') ? 'bg-teal-600 text-white' : 'active:bg-teal-700 active:text-white hover:bg-teal-50'}`}>
            <i className={`${iconClass('/employer-dashboard')}  fi-rr-chart-simple-horizontal`}></i>
            Dashboard
          </button>

          {/* Jobs */}
          <button onClick={navigateTo('/employer-jobs')} className={`flex items-center pl-7 py-4 px-3 subheader hover:border-l-teal-400 hover:border-l-4 ${isActive('/employer-jobs') ? 'bg-teal-600 text-white' : 'active:bg-teal-700 active:text-white hover:bg-teal-50'}`}>
            <i className={`${iconClass('/employer-jobs')} fi-rr-briefcase-blank`}></i>
            Jobs
          </button>

          {/* Candidates */}
          <button onClick={navigateTo('/employer-candidates')} className={`flex items-center pl-7 py-4 px-3 subheader hover:border-l-teal-400 hover:border-l-4 ${isActive('/employer-candidates') ? 'bg-teal-600 text-white' : 'active:bg-teal-700 active:text-white hover:bg-teal-50'}`}>
            <i className={`${iconClass('/employer-candidates')} fi-rr-users-alt`}></i>
            Candidates
          </button>

          {/* Tests */}
          <button onClick={navigateTo('/employer-tests')} className={`flex items-center pl-7 py-4 px-3 subheader hover:border-l-teal-400 hover:border-l-4 ${isActive('/employer-tests') ? 'bg-teal-600 text-white' : 'active:bg-teal-700 active:text-white hover:bg-teal-50'}`}>
            <i className={`${iconClass('/employer-tests')} fi fi-rr-assept-document`}></i>
            Tests
          </button>

          {/* Reports */}
          <button onClick={navigateTo('/employer-reports')} className={`flex items-center pl-7 py-4 px-3 subheader hover:border-l-teal-400 hover:border-l-4 ${isActive('/employer-reports') ? 'bg-teal-600 text-white' : 'active:bg-teal-700 active:text-white hover:bg-teal-50'}`}>
            <i className={`${iconClass('/employer-reports')} fi-rr-chart-pie-alt`}></i>
            Reports
          </button>
        </div>
      </div>

      <div className="mt-auto flex flex-col text-center">
        {/* Account */}
        <button onClick={navigateTo('/employer-account')} className={`flex items-center pl-7 py-4 px-3 subheader hover:border-l-teal-400 hover:border-l-4 ${isActive('/employer-account') ? 'bg-teal-600 text-white' : 'active:bg-teal-700 active:text-white hover:bg-teal-50'}`}>
          <i className={`${iconClass('/employer-account')} fi-rr-circle-user`}></i>
          Account
        </button>

        {/* Support */}
        <button onClick={navigateTo('/employer-support')} className={`flex items-center pl-7 py-4 px-3 subheader hover:border-l-teal-400 hover:border-l-4 ${isActive('/employer-support') ? 'bg-teal-600 text-white' : 'active:bg-teal-700 active:text-white hover:bg-teal-50'}`}>
          <i className={`${iconClass('/employer-support')} fi-rr-user-headset`}></i>
          Support
        </button>
      </div>

      <span className="mini p-5 text-center"> © 2024 EvaluHire Inc. All Rights Reserved. </span>
    </div>
  );
}

export default Sidebar;
