import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/EmployerComponents/Sidebar';
import Searchbar from '../components/EmployerComponents/Searchbar';
import SortCandidates from '../components/EmployerComponents/SortCandidates';
import EmployerCandidatesData from '../Data/EmployerCandidatesData';
import Dropdown from '../components/SharedComponents/Dropdown';
import InvitePopup from '../components/EmployerComponents/InvitePopup';

function EmployerCandidates() {
  let navigate = useNavigate();

  const statusOptions = ["New Application", "Reject", "Interview", "Shortlist", "Second Interview", "Hire"];
  const actionOptions = ["Send email", "Schedule Interview", "Send Text"];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(EmployerCandidatesData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = EmployerCandidatesData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getScoreColor = (score) => {
    const colors = [
      "#dc2626", // 0-9% red
      "#f87171", // 10-19% light red
      "#fb923c", // 20-29%
      "#fbbf24", // 30-39%
      "#facc15", // 40-49%
      "#a3e635", // 50-59%
      "#4ade80", // 60-69%
      "#34d399", // 70-79%
      "#2dd4bf", // 80-89%
      "#0d9488"  // 90-100%
    ];
    const index = Math.min(Math.max(Math.floor(score / 10), 0), 9);
    return colors[index];
  };

  // At the top where you define your states
const [showSortOptions, setShowSortOptions] = useState(false);
const [sortRules, setSortRules] = useState([]);

const updateRule = (index, value, isJob = false) => {
  const newRules = [...sortRules];
  if (isJob) {
    // If it's a job rule, we may want to handle it differently
    // For simplicity, let's just set the rule directly
    newRules[index].rule = value;
  } else {
    newRules[index].rule = value;
  }
  setSortRules(newRules);
};



  return (
    <div className="flex min-h-screen bg-background-gray">
      <Sidebar navigateTo={navigate} />
      <div className="flex-1 flex flex-col">
        <Searchbar />

        <div className="flex-1 px-6  overflow-auto">
          <div className="bg-background-gray ">

            <div className="flex justify-between rounded-md  items-center par mb-5 ">
              <div className="flex space-x-2 items-center">
              <i className="fi sm-title fi-rr-filter mr-2"></i>
                <Dropdown
                  label="Position"
                  options={["Position 1", "Position 2", "Position 3"]}
                  onSelect={(selectedOption) => console.log("Selected Position:", selectedOption)}
                />
                <Dropdown
                  label="Status"
                  options={statusOptions}
                  onSelect={(selectedOption) => console.log("Selected Status:", selectedOption)}
                />
                <Dropdown
                  label="Date"
                  options={["Earliest First", "Latest First"]}
                  onSelect={(selectedOption) => console.log("Selected Date:", selectedOption)}
                />
                <Dropdown
                  label="Score"
                  options={["Highest to Lowest", "Lowest to Highest"]}
                  onSelect={(selectedOption) => console.log("Selected Score:", selectedOption)}
                />






                <SortCandidates sortRules={sortRules} setSortRules={setSortRules} />










              </div>
              <InvitePopup />
            </div>

            <table className="w-full bg-teal-800 shadow rounded-md overflow-hidden">
              <thead className=" ">
                <tr className="border-b  border-gray-100">

                  <th className="p-2 pl-4 min-w-40 text-left subheader font-normal text-white">Candidate</th>
                  <th className="p-2 text-left subheader font-normal text-white ">Job</th>
                  <th className="p-2 text-left subheader font-normal text-white">Test</th>
                  <th className="p-2 text-left subheader font-normal text-white">Score</th>
                  <th className="p-2 text-left subheader font-normal text-white">Activity</th>
                  <th className="p-2 text-left subheader font-normal text-white">Status</th>
                  <th className="p-2 text-left subheader font-normal text-white" >Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {currentItems.map(candidate => (
                  <tr key={candidate.id} className="border-b border-gray-100">

                    <td className="px-4 py-1 par">

                    <div className="grid grid-rows-2">

<div>
  <i className="fi fi-rr-user mini mr-2"></i><span className="par">{candidate.name}</span>
</div>

<div>
<i className="fi fi-rr-form mini mr-1"></i><span className="tiny"> View </span>
</div>



                    </div>

                    </td>


                    <td className="px-4 py-1 par "><span className="bg-wise-yellow px-1 rounded-md border-gray-300 border drop-shadow-sm">{candidate.position}</span></td>
                    <td className="px-4 py-1 par"><span className="bg-very-light-teal px-1 rounded-md border-gray-300 border drop-shadow-sm">{candidate.test}</span></td>

                    <td className="px-4 py-1 par">
<div className="flex items-center justify-center">
<div className="relative w-10 h-10">
<div className="absolute inset-0 flex items-center justify-center" style={{ fontSize: "0.75rem" }}>
{candidate.score}%
</div>
<svg viewBox="0 0 36 36" className="w-full h-full">
<path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#e5e7eb" strokeWidth="4" strokeDasharray="100, 100"></path>
<path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke={getScoreColor(candidate.score)} strokeWidth="4" strokeDasharray={`${candidate.score}, 100`}></path>
</svg>
</div>
</div>
</td>


                    <td className="px-4 py-1 par">03/08/2024</td>
                    <td className="px-4 py-1 par">
                      <select className="p-2 rounded-lg border-gray-200 bg-white">
                        {statusOptions.map((status) => (
                          <option key={status} value={status}>{status}</option>
                        ))}
                      </select>
                    </td>
                    <td className="px-4 py-1 par">
                      <select className="p-2 rounded-lg border-gray-200 bg-white">
                        {actionOptions.map((action) => (
                          <option key={action} value={action}>{action}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-center space-x-2 mt-10 my-4">
              {currentPage > 1 && (
                <button
                  className="px-4 py-1 par bg-white border border-teal-700 rounded-md hover:bg-background-gray transition duration-150 ease-in-out"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </button>
              )}
              {[...Array(totalPages).keys()].map(number => (
                <button
                  key={number + 1}
                  className={`px-4 py-1 border border-teal-700 rounded-md hover:bg-background-gray ${currentPage === number + 1 ? 'bg-teal-50 par' : 'bg-white hover:bg-background-gray'}`}
                  onClick={() => paginate(number + 1)}
                >
                  {number + 1}
                </button>
              ))}
              {currentPage < totalPages && (
                <button
                  className="px-4 py-1 rounded-md border border-teal-700 bg-white hover:bg-background-gray"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployerCandidates;
