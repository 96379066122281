import React from 'react';


const EmployerCandidatesData = [
  {
    id: 1,
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "123-456-7890",
    position: "Software Engineer",
    test: "Coding Test",
    score: 95,
    timeTaken: "45 mins",
    status: "New Candidate",
    action: "Send Email"
  },
  {
      id: 2,
      name: "Cody Martinez",
      email: "gardnerwilliam@gmail.com",
      phone: "861-589-9090",
      position: "Product Manager",
      test: "Logic Test",
      score: 32,
      timeTaken: "30 mins",
      status: "Hired",
      action: "Send Email"
    },
    {
      id: 3,
      name: "Curtis Wolf",
      email: "vanessa48@gmail.com",
      phone: "509-105-1417",
      position: "Product Manager",
      test: "Design Test",
      score: 80,
      timeTaken: "60 mins",
      status: "Rejected",
      action: "Schedule Interview"
    },
    {
      id: 4,
      name: "Kevin Johnson",
      email: "james82@yahoo.com",
      phone: "202-876-4764",
      position: "Data Analyst",
      test: "Design Test",
      score: 60,
      timeTaken: "60 mins",
      status: "New Candidate",
      action: "Schedule Interview"
    },
    {
      id: 5,
      name: "Rodney Townsend",
      email: "braunsarah@robertson.biz",
      phone: "777-389-5147",
      position: "Software Engineer",
      test: "Logic Test",
      score: 52,
      timeTaken: "45 mins",
      status: "Hired",
      action: "Send Text"
    },
    {
      id: 6,
      name: "Chelsea Lindsey",
      email: "bettyhebert@yahoo.com",
      phone: "853-946-9056",
      position: "Software Engineer",
      test: "Coding Test",
      score: 46,
      timeTaken: "75 mins",
      status: "Rejected",
      action: "Send Email"
    },
    {
      id: 7,
      name: "Matthew Parks",
      email: "fpowell@hotmail.com",
      phone: "591-667-9980",
      position: "UX Designer",
      test: "Logic Test",
      score: 1,
      timeTaken: "45 mins",
      status: "Rejected",
      action: "Send Text"
    },
    {
      id: 8,
      name: "Adam Alexander",
      email: "willie27@gmail.com",
      phone: "599-498-7868",
      position: "Software Engineer",
      test: "Logic Test",
      score: 92,
      timeTaken: "75 mins",
      status: "New Candidate",
      action: "Send Text"
    },
    {
      id: 9,
      name: "Michael Haynes",
      email: "tbrooks@ray-wilson.com",
      phone: "321-258-9603",
      position: "Data Analyst",
      test: "Design Test",
      score: 12,
      timeTaken: "60 mins",
      status: "Hired",
      action: "Send Text"
    },
    {
      id: 10,
      name: "Michael James",
      email: "lebron@gmail.com",
      phone: "321-258-9645",
      position: "Trumpet Specialist",
      test: "Design Test",
      score: 34,
      timeTaken: "45 mins",
      status: "Hired",
      action: "Send Text"
    },
    {
      id: 11,
      name: "Chris Tucker",
      email: "ctucker@yahoo.ca.co.gov.uk",
      phone: "321-258-5178",
      position: "Comedian",
      test: "Design Test",
      score: 67,
      timeTaken: "42 mins",
      status: "Hired",
      action: "Send Text"
    },
    {
      id: 12,
      name: "Chris Paul",
      email: "cp3@gmail.com",
      phone: "321-258-9607",
      position: "Sports Analyst",
      test: "Beep Test",
      score: 47,
      timeTaken: "60 mins",
      status: "Rejected",
      action: "Send Text"
    },
    {
      id: 13,
      name: "John Doe",
      email: "john.doe@example.com",
      phone: "123-456-7890",
      position: "Software Engineer",
      test: "Coding Test",
      score: 95,
      timeTaken: "45 mins",
      status: "New Candidate",
      action: "Send Email"
    },
    {
        id: 14,
        name: "Cody Martinez",
        email: "gardnerwilliam@gmail.com",
        phone: "861-589-9090",
        position: "Product Manager",
        test: "Logic Test",
        score: 32,
        timeTaken: "30 mins",
        status: "Hired",
        action: "Send Email"
      },
      {
        id: 15,
        name: "Curtis Wolf",
        email: "vanessa48@gmail.com",
        phone: "509-105-1417",
        position: "Product Manager",
        test: "Design Test",
        score: 80,
        timeTaken: "60 mins",
        status: "Rejected",
        action: "Schedule Interview"
      },
      {
        id: 16,
        name: "Kevin Johnson",
        email: "james82@yahoo.com",
        phone: "202-876-4764",
        position: "Data Analyst",
        test: "Design Test",
        score: 60,
        timeTaken: "60 mins",
        status: "New Candidate",
        action: "Schedule Interview"
      },
      {
        id: 17,
        name: "Rodney Townsend",
        email: "braunsarah@robertson.biz",
        phone: "777-389-5147",
        position: "Software Engineer",
        test: "Logic Test",
        score: 52,
        timeTaken: "45 mins",
        status: "Hired",
        action: "Send Text"
      },
      {
        id: 18,
        name: "Chelsea Lindsey",
        email: "bettyhebert@yahoo.com",
        phone: "853-946-9056",
        position: "Software Engineer",
        test: "Coding Test",
        score: 46,
        timeTaken: "75 mins",
        status: "Rejected",
        action: "Send Email"
      },
      {
        id: 19,
        name: "Matthew Parks",
        email: "fpowell@hotmail.com",
        phone: "591-667-9980",
        position: "UX Designer",
        test: "Logic Test",
        score: 1,
        timeTaken: "45 mins",
        status: "Rejected",
        action: "Send Text"
      },
      {
        id: 20,
        name: "Adam Alexander",
        email: "willie27@gmail.com",
        phone: "599-498-7868",
        position: "Software Engineer",
        test: "Logic Test",
        score: 92,
        timeTaken: "75 mins",
        status: "New Candidate",
        action: "Send Text"
      },
      {
        id: 21,
        name: "Michael Haynes",
        email: "tbrooks@ray-wilson.com",
        phone: "321-258-9603",
        position: "Data Analyst",
        test: "Design Test",
        score: 12,
        timeTaken: "60 mins",
        status: "Hired",
        action: "Send Text"
      },
      {
        id: 22,
        name: "Michael James",
        email: "lebron@gmail.com",
        phone: "321-258-9645",
        position: "Trumpet Specialist",
        test: "Design Test",
        score: 34,
        timeTaken: "45 mins",
        status: "Hired",
        action: "Send Text"
      },
      {
        id: 23,
        name: "Chris Tucker",
        email: "ctucker@yahoo.ca.co.gov.uk",
        phone: "321-258-5178",
        position: "Comedian",
        test: "Design Test",
        score: 67,
        timeTaken: "42 mins",
        status: "Hired",
        action: "Send Text"
      },
      {
        id: 24,
        name: "Chris Paul",
        email: "cp3@gmail.com",
        phone: "321-258-9607",
        position: "Sports Analyst",
        test: "Beep Test",
        score: 47,
        timeTaken: "60 mins",
        status: "Rejected",
        action: "Send Text"
      },




];

export default EmployerCandidatesData;
