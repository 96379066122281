import React from 'react';
import { useNavigate } from 'react-router-dom';

function RegisterPage() {
  let navigate = useNavigate();

  return (
    <div className="flex min-h-screen">
      {/* Left Side: Register Form */}
      <div className="bg-background-gray flex flex-1 flex-col justify-center items-center px-4">
        {/* EvaluHire Text centered at the top of the form */}
        <div className="w-full flex justify-center mb-10">
          <button onClick={() => navigate('/')} className="text-center">
            <h1 className="big-title">
              <span className="text-teal-500">Evalu</span><span className="text-teal-800">Hire</span>
            </h1>
          </button>
        </div>

        {/* Registration Form */}
        <div className="w-full max-w-xs">
          <h2 className="md-title text-left">Create Account</h2>
          <p className="text-left mb-8">Join our platform and start hiring better instantly.</p>
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="text" placeholder="First Name" />
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="text" placeholder="Last Name" />
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="text" placeholder="Company Name" />
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="email" placeholder="Email" />
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="tel" placeholder="Phone Number" />
          <select className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300">
            <option value="">Team Size</option>
            <option value="less_than_5">Less Than 5</option>
            <option value="between_6_25">Between 6 - 25</option>
            <option value="between_26_50">Between 26 & 50</option>
            <option value="between_51_100">Between 51 & 100</option>
            <option value="more_than_100">100+</option>
          </select>
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="password" placeholder="Password" />
          <input className="w-full mb-4 p-2 rounded text-gray-700 border border-gray-300" type="password" placeholder="Verify Password" />
          <button className="border-white bg-teal-800 hover:bg-white hover:border hover:border-teal-800 text-white hover:text-teal-900 font-bold py-2 px-4 rounded w-full mb-4" onClick={() => console.log('Register')}>Register</button>
          <p className="par text-left">Already have an account? <a className="text-blue-600 hover:text-blue-800" href="#" onClick={(e) => {e.preventDefault(); navigate('/signin');}}>Sign In</a>.</p>
        </div>
      </div>

      {/* Right Side: Image Background */}
      <div className="flex-1" style={{ backgroundImage: "url('https://i.ibb.co/4pFJBF1/image-720.png')", backgroundSize: 'cover', backgroundPosition: 'center' }}>
        {/* No content here, just background image */}
      </div>
    </div>
  );
}

export default RegisterPage;
