import React from 'react';

const generateRandom = () => Math.floor(Math.random() * 100) + 1;

const OpenJobs = () => {
  const jobs = [
    { title: 'Software Engineer', candidates: generateRandom(), interviews: generateRandom(), shortlisted: generateRandom() },
    { title: 'UI Designer', candidates: generateRandom(), interviews: generateRandom(), shortlisted: generateRandom() },
    { title: 'Branding Design Intern', candidates: generateRandom(), interviews: generateRandom(), shortlisted: generateRandom() },
  ];

  const jobColors = ['bg-white', 'bg-white', 'bg-white'];

  return (
    <div>
      <div className="sm-title">Open Jobs</div>
      {jobs.map((job, index) => (
        <div key={index} className={`mt-4 p-2 rounded-md ${jobColors[index % jobColors.length]} shadow border border-gray-200 space-y-2`}>
          <h3 className="subheader ">{job.title}<i className=" ml-1 py-2 subheader fi fi-tr-angle-double-small-right"></i></h3>


<div className="grid grid-cols-3 justify-center bg-white border border-gray-200 rounded-md">

            {/* Candidates */}
          <div className=" p-1 px-2 border-r border-gray-200 bg-very-light-green">
            <span className="mini">Candidates:</span>
            <div className="flex justify-between items-center w-full mt-1 ">
              <i className="fi fi-rr-users-alt sm-title"></i>
              <span className="par font-bold ">{job.candidates}</span>
            </div>
          </div>

          {/* Interviews */}
          <div className=" p-1 px-2 border-r border-gray-200 bg-very-light-purple">
            <span className="mini">Interviews:</span>
            <div className="flex justify-between items-center w-full mt-1">
              <i className="fi fi-rr-meeting sm-title"></i>
              <span className="par font-bold  ">{job.interviews}</span>
            </div>
          </div>

          {/* Shortlisted */}
          <div className=" p-1 px-2 bg-soft-pink">
            <span className="mini">Shortlisted:</span>
            <div className="flex justify-between items-center ">
              <i className="fi fi-rr-circle-star sm-title"></i>
              <span className="par font-bold ">{job.shortlisted}</span>
            </div>
          </div>


</div>


        </div>
      ))}
    </div>
  );
};

export default OpenJobs;
