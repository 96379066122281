// SortCandidates.js
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const SortCandidates = ({ sortRules, setSortRules }) => {
  const [showSortOptions, setShowSortOptions] = useState(false);

  const addNewSortRule = () => {
    if (sortRules.length < 3) {
      setSortRules([...sortRules, { category: 'Date', rule: 'Earliest First' }]);
    }
  };

  const updateSortRule = (index, category, value) => {
    const newRules = [...sortRules];
    newRules[index] = { category, rule: value };
    setSortRules(newRules);
  };

  const removeSortRule = (index) => {
    setSortRules(sortRules.filter((_, i) => i !== index));
  };

  const renderSortOptions = (rule, index) => {
    switch (rule.category) {
      case "Date":
        return (
          <select onChange={(e) => updateSortRule(index, "Date", e.target.value)} className="w-full p-1 border rounded-md">
            <option value="Earliest First">Earliest First</option>
            <option value="Latest First">Latest First</option>
          </select>
        );
      case "Score":
        return (
          <select onChange={(e) => updateSortRule(index, "Score", e.target.value)} className="w-full p-1 border rounded-md">
            <option value="Highest First">Highest First</option>
            <option value="Lowest First">Lowest First</option>
          </select>
        );
      case "Job":
        return (
          <div className="flex flex-wrap">
            {["Software Engineer", "UX Designer", "Data Analyst", "Product manager"].map((job, jIndex) => (
              <label key={jIndex} className="inline-flex items-center mr-2 mb-2">
                <input type="checkbox" className="form-checkbox" value={job} onChange={(e) => updateSortRule(index, "Job", e.target.value, true)} />
                <span className="ml-2">{job}</span>
              </label>
            ))}
          </div>
        );
      default:
        return null;
    }
  };


  const onDragEnd = (result) => {
   const { destination, source } = result;

   // If there is no destination (i.e., the item was dropped outside), do nothing
   if (!destination) {
     return;
   }

   // If the item's start position and end position are the same, do nothing
   if (
     destination.droppableId === source.droppableId &&
     destination.index === source.index
   ) {
     return;
   }

   // Create a new array for the rules
   const newRules = Array.from(sortRules);
   // Remove the item from its original position
   const [reorderedItem] = newRules.splice(source.index, 1);
   // Insert the item at its new position
   newRules.splice(destination.index, 0, reorderedItem);

   // Update the state with the new rules array
   setSortRules(newRules);
 };



 return (
     <div className="relative inline-block text-left">
       {/* Trigger button */}
       <div className="flex items-center cursor-pointer select-none" onClick={() => setShowSortOptions(!showSortOptions)}>
         <span className="px-2 py-1 rounded-md border drop-shadow-sm flex items-center space-x-2 bg-white border border-gray-300 hover:bg-teal-700 hover:text-white">Sorted by {sortRules.length} fields <i className="fi fi-rr-angle-small-down ml-2 text-sm"></i></span>

       </div>

       {/* Dropdown */}
         {showSortOptions && (
           <DragDropContext onDragEnd={onDragEnd}>
             <Droppable droppableId="droppable">
               {(provided) => (
                 <div
                   {...provided.droppableProps}
                   ref={provided.innerRef}
                   className="origin-top-left absolute right-0 mt-1 rounded-md shadow-lg z-50 w-64"
                 >




                   <div className="rounded-md bg-white border border-gray-300 shadow-xs">

                     <div className="py-2 px-4 mb-2">
                     <div className="par  mt-1 pb-2 border-b border-gray-300"> Sort by: </div>
                       {sortRules.length < 3 && (
                         <div
                           className=" mt-2 text-sm text-teal-500 hover:bg-teal-50 cursor-pointer"
                           onClick={addNewSortRule}
                         >
                           Add another sort
                         </div>
                       )}
                       {sortRules.map((rule, index) => (
                         <Draggable key={index} draggableId={`item-${index}`} index={index}>
                           {(provided) => (
                             <div
                               ref={provided.innerRef}
                               {...provided.draggableProps}
                               className=" py-2 flex items-center border-b border-gray-200"
                             >
                               {/* Rule category selector */}
                               <select
                                 className=" rounded-md "
                                 value={rule.category}
                                 onChange={(e) => updateSortRule(index, e.target.value, rule.rule)}
                               >
                                 <option value="Date">Date</option>
                                 <option value="Score">Score</option>
                                 <option value="Job">Job</option>
                               </select>

                               {/* Rule option selector right next to the category */}
                               <div className="flex-grow p-1">
                                 {renderSortOptions(rule, index)}
                               </div>

                               {/* Drag handle icon */}
                               <i {...provided.dragHandleProps} className="fi fi-rr-arrows cursor-move text-gray-500 mx-2"></i>

                               {/* Remove rule button */}
                               <button
                                 onClick={() => removeSortRule(index)}
                                 className="text-red-500 hover:text-red-700"
                               >
                                 &times;
                               </button>
                             </div>
                           )}
                         </Draggable>
                       ))}
                       {provided.placeholder}
                     </div>
                   </div>
                 </div>
               )}
             </Droppable>
           </DragDropContext>
         )}
     </div>
   );
 };

 export default SortCandidates;
