import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const EditJobPopup = ({ job, onClose, onUpdate }) => {

  const [jobType, setJobType] = useState('');
  const [salaryType, setSalaryType] = useState('');

  const [jobPostDate, setJobPostDate] = useState(null);
  const [applicationEndDate, setApplicationEndDate] = useState(null);
  // Assuming job object contains all fields used in your EmployerAddJobPage.js
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    requirements: '',
    jobType: '',
    location: '',
    salaryType: '',
    jobPostDate: null,
    applicationEndDate: null,
  });

  useEffect(() => {
    if (job) {
      setFormData({
        ...job, // Spread the job details into the formData state
      });
    }
  }, [job]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setFormData({ ...formData, [name]: date });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(formData); // Placeholder function to update job details
    onClose(); // Close the popup
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transpar font-boldent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:" aria-label="close">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <form onSubmit={handleSubmit} className="space-y-4 p-8 bg-teal-50 rounded-md">

          <div>
            <label className="block par font-bold ">Job Title</label>
            <input type="text" className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm" placeholder="" />
          </div>

          <div>
            <label className="block par font-bold ">Job Description</label>
            <textarea className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" rows="4" placeholder=""></textarea>
          </div>

          <div>
            <label className="block par font-bold ">Job Requirements</label>
            <textarea className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" rows="4" placeholder=""></textarea>
          </div>

          <div>
            <label className="block par font-bold ">Select Job Test</label>
            <select className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm" value={jobType} onChange={(e) => setJobType(e.target.value)}>
              <option value="Test Option 1">Test Option 1 </option>
              <option value="Test Option 2">Test Option 2</option>
              <option value="Test Option 3">Test Option 3</option>
            </select>
          </div>

  <div className="flex space-x-4">
          <div className="">
       <label htmlFor="jobPostDate" className="block par font-bold ">Job Post Date</label>
       <DatePicker
         id="jobPostDate"
         selected={jobPostDate}
         onChange={date => setJobPostDate(date)}
         className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
       />
     </div>
     <div className="">
       <label htmlFor="applicationEndDate" className="block par font-bold ">Application End Date</label>
       <DatePicker
         id="applicationEndDate"
         selected={applicationEndDate}
         onChange={date => setApplicationEndDate(date)}
         className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
       />
     </div>
     </div>



          <div>
            <label className="block par font-bold ">Job Type</label>
            <select className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm" value={jobType} onChange={(e) => setJobType(e.target.value)}>
              <option value="">Select Job Type</option>
              <option value="In-Location">In-Location</option>
              <option value="Remote">Remote</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>

          {(jobType === 'In-Location' || jobType === 'Hybrid') && (
            <div>
              <label className="block par font-bold">Location Address</label>
              <input type="text" className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm" placeholder="" />
            </div>
          )}

          <div>
            <label className="block par font-bold ">Salary Type</label>
            <select className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm" value={salaryType} onChange={(e) => setSalaryType(e.target.value)}>
              <option value="">Select Salary Type</option>
              <option value="Range">Range</option>
              <option value="Flat">Flat</option>
              <option value="Hide">Hide</option>
            </select>
          </div>

          {salaryType === 'Range' && (
            <div className="grid grid-cols-2 gap-4">
              <input type="text" className="form-input mt-1 block w-full p-2" placeholder="Low Range" />
              <input type="text" className="form-input mt-1 block w-full p-2" placeholder="High Range" />
            </div>
          )}

          {salaryType === 'Flat' && (
            <input type="text" className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm" placeholder="Flat Salary" />
          )}

<div className="flex pt-4 justify-center">

            <button type="submit" className="bg-white border border-teal-700 hover:bg-teal-700 hover:text-white px-4 py-2 rounded-md subheader transition duration-150 ease-in-out">
              Publish
            </button>

            </div>

          </form>



        </div>
      </div>
    </div>
  );
};

export default EditJobPopup;
